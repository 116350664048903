import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AddressDetail from "./pages/address/detail";
import AddressList from "./pages/address/list";
import Auth from "./pages/auth";
import Home from "./pages/home";
import Confirmation from "./pages/order/confirmation";
import OrderDetail from "./pages/order/detail";
import OrderList from "./pages/order/orders";
import Prescription from "./pages/prescription";

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={"/h5"}>
        <Auth>
          <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/" element={<Home />}></Route>
            <Route path="/prescription" element={<Prescription />}></Route>
            <Route path="/order/confirmation/:id" element={<Confirmation />}></Route>
            <Route path="/order/list" element={<OrderList />}></Route>
            <Route path="/order/detail/:id" element={<OrderDetail />}></Route>
            <Route path="/address/list" element={<AddressList />}></Route>
            <Route path="/address/detail" element={<AddressDetail />}></Route>
            <Route path="/address/detail/:id" element={<AddressDetail />}></Route>
          </Routes>
        </Auth>
      </BrowserRouter>
    );
  }
}
