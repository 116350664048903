import medIcon from "@/assets/icons/icon_med.png";
import { Button, Divider, Image, Popup } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import classNames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logistics } from "../../../models/Logistics";
import { Order } from "../../../models/Order";
import { getOrderList, getOrderLogistics } from "../../../services/OrderService";
import { OrderStatus } from "../../../utils/Const";
import { getPrice } from "../../../utils/Utils";
import DrugItem from "../components/DrugItem";
import LogisticsInfo from "../components/LogisticsInfo";
import styles from "./index.module.scss";

export default function OrderList() {
  const [showLogisticsInfo, setShowLogisticsInfo] = useState(false);
  const [data, setData] = useState<Order[]>([]);
  const [logistics, setLogistics] = useState<Logistics[]>([]);
  const history = useNavigate();

  const statusElem = useCallback((orderStatus: string) => {
    let colorCls = styles.normal;
    const text = OrderStatus[orderStatus as keyof typeof OrderStatus];
    switch (orderStatus) {
      case "WAIT_PAY":
        colorCls = styles.waiting;
        break;
      case "PAY_SUCCESS":
        colorCls = styles.success;
        break;
    }
    return <div className={classNames(styles.status, colorCls)}>{text}</div>;
  }, []);

  const getList = useCallback(async () => {
    const res = await getOrderList();
    setData(res);
  }, []);

  const getLogistics = useCallback(async (id: string) => {
    const res = await getOrderLogistics(id);
    setLogistics(res);
    setShowLogisticsInfo(true);
  }, []);

  const toDetail = useCallback((id: string) => {
    history(`/order/detail/${id}`);
  }, []);

  useEffect(() => {
    document.title = "订单列表";
    getList();
  }, []);

  return (
    <div className={styles.orders}>
      {data.map((item) => (
        <div key={item.id} className={styles.item}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              {statusElem(item.orderStatus)}
              <div className={styles.time}>
                {moment(item.createdDate).format("YYYY-MM-DD HH:mm:ss")}
              </div>
            </div>
            <Divider></Divider>
            <div className={styles.cardContent}>
              {item.items.length > 1 ? (
                <>
                  <div className={styles.picsWrapper}>
                    {item.items.map((drug) => (
                      <Image
                        key={drug.id}
                        src={drug.picPath ?? medIcon}
                        className={styles.medPic}
                      ></Image>
                    ))}
                  </div>
                  <div className={styles.countWrapper}>
                    <div className={styles.totalPrice}>¥ {getPrice(item.payPrice)}</div>
                    <div className={styles.totalCount}>共 {item.items.length} 件</div>
                  </div>
                </>
              ) : (
                <DrugItem
                  priceCls={styles.totalPrice}
                  countCls={styles.totalCount}
                  drug={item.items[0]}
                ></DrugItem>
              )}
            </div>
            <div className={styles.cardFooter}>
              {item.orderStatus === "DELIVER" ? (
                <div className={styles.logisticsWrapper} onClick={() => getLogistics(item.id)}>
                  <span>查看物流详情</span>
                  <RightOutline className={styles.icon} fontSize={16} />
                </div>
              ) : (
                <div></div>
              )}

              <Button
                color="primary"
                fill="outline"
                className={styles.btn}
                onClick={() => toDetail(item.id)}
              >
                查看订单
              </Button>
            </div>
          </div>
        </div>
      ))}

      <Popup
        visible={showLogisticsInfo}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          maxHeight: "70vh",
          overflow: "auto"
        }}
      >
        <LogisticsInfo
          logistics={logistics[0]}
          close={() => setShowLogisticsInfo(false)}
        ></LogisticsInfo>
      </Popup>
    </div>
  );
}
