export const PayStatus = {
  NO_PAY: "未支付",
  PAY_SUCCESS: "支付成功",
  PAY_FAIL: "支付失败"
};
export const OrderStatus = {
  CREATE: "已创建",
  WAIT_PAY: "待支付",
  PAYING: "支付中",
  PAY_SUCCESS: "已支付",
  DELIVER: "已发货",
  DELIVER_SIGN: "已签收",
  COMPLETE: "已完成",
  AUTO_CANCEL: "已取消"
};
