import appStyles from "@/app.module.scss";
import { Divider } from "antd-mobile";
import React from "react";
import { Drug } from "../../../models/PrescriptionDetail";
import styles from "./index.module.scss";

export default function DrugList(props: { drugs: Drug[] }) {
  const { drugs } = props;
  return (
    <div className={styles.drugWrapper}>
      <div className={appStyles.title}>药品/处方</div>
      {drugs?.map((item, index) => (
        <div key={item.id}>
          <div className={styles.drugItem}>
            <div className={styles.specWrapper}>
              <div
                className={styles.name}
              >{`${item.drugCommonName}【${item.drugSpecification}】`}</div>
              <div className={styles.count}>* {item.count}</div>
            </div>
            <div className={styles.usageWrapper}>
              <div className={styles.usage}>
                每次使用：每次{item.usagePerUseCount + item.usagePerUseUnit}
              </div>
              <div className={styles.usage}>
                用法：每次{item.usagePerUseCount + item.usagePerUseUnit}/{item.useFrequency}/
                {item.usageMethod}
                {item.usageFrequencyCount}
              </div>
            </div>
          </div>
          {index + 1 === drugs.length ? "" : <Divider />}
        </div>
      ))}
    </div>
  );
}
