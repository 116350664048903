import medIcon from "@/assets/icons/icon_med.png";
import { Ellipsis, Image } from "antd-mobile";
import classNames from "classnames";
import React from "react";
import { Drug } from "../../../models/PrescriptionDetail";
import { getPrice } from "../../../utils/Utils";
import styles from "./index.module.scss";

interface IProps {
  drug: Drug;
  priceCls?: string;
  countCls?: string;
}
export default function DrugItem(props: IProps) {
  const { drug, priceCls, countCls } = props;
  return (
    <div className={styles.itemWrapper}>
      <div className={styles.imgWrapper}>
        <Image src={drug.picPath ?? medIcon} className={styles.img}></Image>
      </div>
      <div className={styles.desWrapper}>
        <div className={styles.leftContent}>
          <div className={styles.name}>
            <Ellipsis rows={2} content={drug.drugProductNameCn} className={styles.ellipsis} />
          </div>
          <div className={styles.other}>
            <Ellipsis
              content={`厂家 ${drug.manufacturer ? drug.manufacturer : ""}`}
              className={styles.ellipsis}
            />
          </div>
          <div className={styles.other}>
            <span>规格 {drug.drugSpecification}</span>
          </div>
        </div>
        <div className={styles.rightContent}>
          <div className={classNames(styles.price, priceCls)}>¥ {getPrice(drug.retailPrice)}</div>
          <div className={classNames(styles.count, countCls)}>x {drug.count}</div>
        </div>
      </div>
    </div>
  );
}
