import netService, { ReqMethods } from "./Net";

const apis = {
  detail: (id: string) => `/prescriptions/out/${id}/detail`,
  hasOrder: (id: string) => `/prescriptions/out/${id}/haveOrder`
};
// 获取处方详情
export const getPrescriptionDetail = (id: string) =>
  netService({
    method: ReqMethods.GET,
    url: apis.detail(id)
  });

// 根据处方获取对应的订单
export const getHasOrder = (id: string) =>
  netService({
    method: ReqMethods.GET,
    url: apis.hasOrder(id)
  });
