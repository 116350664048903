import iconCar from "@/assets/icons/icon_car.png";
import iconCard from "@/assets/icons/icon_card.png";
import iconDone from "@/assets/icons/icon_done.png";
import iconLogistics from "@/assets/icons/icon_logistics.png";
import { Divider, Image, Space, Toast } from "antd-mobile";
import { CloseOutline } from "antd-mobile-icons";
import React, { useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Logistics } from "../../../models/Logistics";
import styles from "./index.module.scss";
import MySteps from "./MySteps";

interface IProp {
  close: () => void;
  logistics: Logistics;
}
export default function LogisticsInfo(props: IProp) {
  const { logistics, close } = props;

  const steps = useMemo(() => {
    const contents = JSON.parse(logistics.content);
    const len = contents.length;
    return contents.map((item: any, index: number) => {
      const isDone = item.context.indexOf("签收") != -1;
      const icon = index === len - 1 ? iconCard : isDone ? iconDone : iconCar;
      return {
        icon: icon,
        title: item.context,
        description: item.time
        // iconClass: isDone ? "" : styles.stepDotIcon,
      };
    });
  }, [logistics]);

  // const steps = [
  //   {
  //     title: "快递已签收",
  //     description: "2022-10-11 20:22",
  //   },
  //   {
  //     title: "快递派送中",
  //     description: `【上海市】上海浦东新区洋泾公司 快递员 徐永琴 13761965829 正在为您派送，快件已消毒，小哥体温正常，将佩戴口罩为您派送，您也可联系小哥将快件放置指定代收点或快递柜，祝您身体健康【95121为韵达业务员外呼专属号码，请放心接听】
  //     <br />
  //     2021-10-11 20:22:43`,
  //     icon: iconCar,
  //   },
  //   {
  //     title: "快递运输中",
  //     description: "2021-10-11 20:22:43",
  //     icon: iconCar,
  //   },
  //   {
  //     title: "已到达[上海市] 发往上海浦西分拨中心",
  //     description: "2021-10-11 20:22:43",
  //     icon: iconDot,
  //     iconClass: styles.stepDotIcon,
  //   },
  //   {
  //     title: "已到达[杭州市] 发往[上海市]",
  //     description: "2021-10-11 20:22:43",
  //     icon: iconDot,
  //     iconClass: styles.stepDotIcon,
  //   },
  //   {
  //     title: "快递已揽件",
  //     description: "2021-10-11 20:22:43",
  //     icon: iconCar,
  //   },
  //   {
  //     title: "商品已发货",
  //     description: "2021-10-11 20:22:43",
  //     icon: iconCard,
  //   },
  //   {
  //     title: "商品已下单,等待商家发货",
  //     description: "2021-10-11 20:22:43",
  //     icon: iconFile,
  //   },
  // ];
  return (
    <div className={styles.logisticsInfoWrapper}>
      <div className={styles.topWrapper}>
        <div className={styles.title}>物流信息</div>
        <div className={styles.closeWrapper} onClick={close}>
          <span>关闭</span>
          <CloseOutline className={styles.closeIcon} />
        </div>
      </div>
      <Divider></Divider>
      <div className={styles.stepWrapper}>
        <div className={styles.desWrapper}>
          <Space className={styles.detailWrapper}>
            <Image src={iconLogistics} className={styles.icon}></Image>
            <span>{logistics.companyName}</span>
            <span>{logistics.logisticsNo}</span>
          </Space>
          <div className={styles.copy}>
            <CopyToClipboard
              text={logistics.logisticsNo}
              onCopy={(text, result) => {
                if (result) {
                  Toast.show({
                    content: "复制成功"
                  });
                } else {
                  Toast.show({
                    content: "复制失败"
                  });
                }
              }}
            >
              <span>复制</span>
            </CopyToClipboard>
          </div>
        </div>
        <MySteps steps={steps}></MySteps>
      </div>
    </div>
  );
}
