import appStyles from "@/app.module.scss";
import { Button, Dialog, Input, Radio, TextArea, Toast } from "antd-mobile";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddressSelector from "../../../components/addressselector";
import { PhoneInput } from "../../../components/phoneinput/index";
import { Address } from "../../../models/Address";
import {
  addAddress,
  deleteAddress,
  getAddressDetail,
  updateAddress
} from "../../../services/AddressService";
import "./antd.scss";
import styles from "./index.module.scss";

export default function AddressDetail() {
  const [showCascader, setShowCascader] = useState(false);
  const [address, setAddress] = useState<Address>({} as Address);
  const params = useParams();
  const history = useNavigate();

  const handleChange = useCallback(
    (value: any, fieldName: string) => {
      const original = { ...address };
      const newAddress = { ...original, [fieldName]: value };
      setAddress(newAddress);
    },
    [address]
  );

  const submit = useCallback(async () => {
    if (!address.realName) {
      Toast.show({ icon: "fail", content: "请输入姓名" });
      return;
    }
    if (!address.mobile) {
      Toast.show({ icon: "fail", content: "请输入手机号" });
      return;
    }
    if (!address.provinceName) {
      Toast.show({ icon: "fail", content: "请选择省市区" });
      return;
    }
    if (!address.address) {
      Toast.show({ icon: "fail", content: "请输入详细地址" });
      return;
    }
    if (address.id) {
      await updateAddress(address);
    } else {
      await addAddress(address);
    }
    history(-1);
  }, [address]);

  const del = useCallback(async () => {
    Dialog.confirm({
      content: "确定删除？",
      onConfirm: async () => {
        await deleteAddress(address);
        history(-1);
      }
    });
  }, [address]);

  const getDetail = useCallback(async (id: string) => {
    const result = await getAddressDetail(id);
    setAddress(result);
  }, []);

  useEffect(() => {
    const id = params.id;
    if (id) {
      document.title = "编辑收货地址";
      getDetail(id);
    } else {
      document.title = "新增收货地址";
    }
  }, []);

  return (
    <div className={classNames(styles.wrapper, "address-detail")}>
      <div className={appStyles.twoColContainer}>
        <div className={appStyles.twoColTop}>
          <div className={styles.block}>
            <div className={styles.item}>
              <span className={styles.field}>姓名</span>
              <Input
                placeholder="请输入姓名"
                className={styles.input}
                clearable
                value={address.realName}
                onChange={(val) => handleChange(val, "realName")}
              />
              {/* <div className={styles.extra}>
                <Image src={userIcon} className={styles.icon}></Image>
                <span>通讯录</span>
              </div> */}
            </div>

            <div className={styles.item}>
              <span className={styles.field}>手机号</span>
              <PhoneInput
                placeholder="请输入手机号"
                value={address.mobile}
                onChange={(val) => handleChange(val, "mobile")}
              ></PhoneInput>
            </div>

            <div className={styles.item}>
              <span className={styles.field}>省市区</span>
              <div className={styles.selectDiv} onClick={() => setShowCascader(true)}>
                {address.provinceName ? (
                  <span className="adm-input-element">
                    {address.provinceName + address.cityName + address.countyName}
                  </span>
                ) : (
                  <span className={styles.pls}>请选择省市区</span>
                )}
              </div>
              <AddressSelector
                show={showCascader}
                value={
                  address.id
                    ? [
                        { label: address.provinceName, value: address.provinceId },
                        { label: address.cityName, value: address.cityId },
                        { label: address.countyName, value: address.countyId }
                      ]
                    : []
                }
                onClose={() => setShowCascader(false)}
                onSubmit={(value) => {
                  if (!value || value.length < 3) {
                    Toast.show({ icon: "fail", content: "请选择完整的省市区" });
                    return;
                  }
                  setShowCascader(false);
                  const original = { ...address };
                  const newAddress = {
                    ...original,
                    ...{
                      provinceName: value[0]?.label,
                      provinceId: value[0]?.value,
                      cityName: value[1]?.label,
                      cityId: value[1]?.value,
                      countyName: value[2]?.label,
                      countyId: value[2]?.value
                    }
                  };
                  setAddress(newAddress);
                }}
              ></AddressSelector>
              {/* <div className={styles.extra}>
                <LocationFill color="#A4D4FF" className={styles.icon} />
                <span>通讯录</span>
              </div> */}
            </div>

            <div className={styles.item}>
              <span className={styles.field}>详细地址</span>
              <TextArea
                placeholder="请输入详细地址"
                autoSize={{ minRows: 2, maxRows: 4 }}
                value={address.address}
                onChange={(val) => handleChange(val, "address")}
              />
              <div className={styles.extra}></div>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.defaultWrapper}>
              <div>设置为默认收货地址</div>
              <Radio
                checked={Boolean(address.isDefault)}
                onChange={(val) => handleChange(val ? 1 : 0, "isDefault")}
              ></Radio>
            </div>
          </div>
        </div>
        <div className={appStyles.twoColBottom}>
          <div className={styles.btnWrapper}>
            {address.id ? (
              <Button className={styles.btnDel} onClick={del}>
                删除
              </Button>
            ) : (
              ""
            )}
            <Button color="primary" className={styles.btnSubmit} onClick={submit}>
              保存
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
