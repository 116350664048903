import { SpinLoading, Toast } from "antd-mobile";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { SystemConfig } from "../../models/SystemConfig";
import { loginByCode } from "../../services/LoginService";
import { getSystemSettings } from "../../services/SystemService";
import { useAppDispatch } from "../../store";
import { selectSystemConfig, updateSystemSettingsThunk } from "../../store/SystemSlice";
import { getToken, setToken } from "../../utils/Token";
import { useAsyncEffect } from "../../utils/Utils";
import styles from "./index.module.scss";

function Auth(props: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [login, setLogin] = useState(false);
  const systemConfig = useSelector(selectSystemConfig);
  const dispatch = useAppDispatch();

  useAsyncEffect(async () => {
    console.log("auth");
    // 登录
    if (!getToken()) {
      const code = searchParams.get("code");
      if (!code) {
        Toast.show({ icon: "fail", content: "登录失败，请联系管理员" });
        return;
      }
      const result = await loginByCode(code);
      setToken(result.id_token);
      setLogin(true);
    } else {
      setLogin(true);
    }
    if (systemConfig.payTimeLimit === 0) {
      const config: SystemConfig = await getSystemSettings();
      dispatch(updateSystemSettingsThunk(config));
    }
  }, [searchParams]);

  return login ? (
    props.children
  ) : (
    <div className={styles.loadingWrapper}>
      <SpinLoading style={{ "--size": "48px" }} />
    </div>
  );
}

export default Auth;
