// import Button from "antd-mobile/es/components/button";
import appStyles from "@/app.module.scss";
import { Button, Divider, Image } from "antd-mobile";
import classNames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PrescriptionDetail } from "../../models/PrescriptionDetail";
import { getHasOrder, getPrescriptionDetail } from "../../services/PrescriptionService";
import DrugList from "./components/DrugList";
import PatientInfo from "./components/PatientInfo";
import styles from "./index.module.scss";

export default function Prescription() {
  const [detail, setDetail] = useState<PrescriptionDetail>({} as PrescriptionDetail);
  const history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasOrder, setHasOrder] = useState(false);
  const [orderId, setOrderId] = useState("");

  const getData = useCallback(async () => {
    const res = await getPrescriptionDetail(searchParams.get("prescriptionId") ?? "");
    setDetail(res);
  }, []);

  const preHasOrder = useCallback(async () => {
    const res = await getHasOrder(searchParams.get("prescriptionId") ?? "");
    setHasOrder(res.haveOrder);
    setOrderId(res.id);
  }, []);

  const toBuy = useCallback((prescriptionId: number) => {
    history(`/order/confirmation/${prescriptionId}`);
  }, []);

  useEffect(() => {
    document.title = "查看处方";
    getData();
    preHasOrder();
  }, []);

  const getPreType = useCallback((type: string) => {
    const map = {
      UNIVERSAL: "通用",
      WESTERN_MEDICINE: "西药",
      HERBAL_MEDICINE: "中草药",
      PATENT_MEDICINE: "中成药"
    };
    return map[type as keyof typeof map];
  }, []);

  const expiredDate = useMemo(() => {
    const expiredDate = new Date(detail.rpCreateDate);
    const hours = expiredDate.getHours();
    expiredDate.setHours(hours + 72);
    return expiredDate;
  }, [detail.rpCreateDate]);
  const toDetail = useCallback((id: string) => {
    history(`/order/detail/${id}`);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.bodyBlock}>
        <div className={styles.block}>
          <div className={styles.num}>处方编号：{detail.outPrescriptionId}</div>
          <div className={styles.hospName}>{detail.hospitalName}</div>
          <div className={styles.preDes}>
            <div className={styles.name}>处方笺</div>
            <div className={styles.preType}>{getPreType(detail.type)}处方</div>
          </div>
          <PatientInfo
            departName={detail.departName}
            patientAge={detail.patientAge}
            patientName={detail.patientName}
            patientGender={detail.patientGender}
            rpCreateDate={detail.rpCreateDate}
          ></PatientInfo>
          <Divider />
          <div className={styles.diagnosisWrapper}>
            <div className={appStyles.title}>诊断</div>
            <div className={styles.diagnosis}>{detail.chiefComplaint}</div>
          </div>
        </div>
        <div className={styles.block}>
          <DrugList drugs={detail.drugs}></DrugList>
        </div>
        <div className={styles.block}>
          <div className={styles.usedWrapper}>
            <div className={appStyles.title}>医嘱</div>
            <div className={styles.used}>{detail.medicalOrder ?? "暂无"}</div>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.signWrapper}>
            <div className={appStyles.title}>签字盖章</div>
            <div className={styles.contentWrapper}>
              <div className={styles.docInfo}>
                <div className={styles.docItem}>
                  <div className={styles.field}>医师签名：</div>
                  <div className={styles.val}>
                    <img src={detail.doctorSignatureUrl} className={styles.signImg}></img>
                  </div>
                </div>
                <div className={styles.docItem}>
                  <div className={styles.field}>药师签名：</div>
                  <div className={styles.val}>
                    <img src={detail.pharmacistSignatureUrl} className={styles.signImg}></img>
                  </div>
                </div>
              </div>
              <div className={styles.hospInfo}>
                <Image
                  width={100}
                  src={detail.hospitalSignatureUrl}
                  className={styles.hospImg}
                  fit={"contain"}
                ></Image>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.tipWrapper}>
            <div className={appStyles.subTitle}>特别提示</div>
            <div className={styles.tips}>
              1、处方有效期为3天，请及时取药。
              <br />
              2、本处方限于互联网医院使用，自行下载配药不具有处方效力。
              <br />
              3、按照卫生部、国家中医药管理局卫医发[2002]24号文件规定：为保证患者用药安全，药品一经发出，不得退换。
            </div>
          </div>
        </div>
      </div>
      {hasOrder ? (
        <div className={styles.bottomBtn}>
          <div className={styles.btnWrapper}>
            <div style={{ textAlign: "center" }}>
              <div className={styles.txt}>当前处方已失效</div>
              <Button
                color="primary"
                fill="solid"
                className={styles.btn}
                onClick={() => toDetail(orderId)}
              >
                查看订单
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.bottomBtn}>
          {expiredDate <= moment().toDate() ? (
            <div className={styles.btnWrapper}>
              <div style={{ textAlign: "center" }}>
                <div className={styles.txt}>当前处方已失效</div>
                <Button
                  color="primary"
                  fill="solid"
                  className={classNames(styles.btn, styles.disabled)}
                >
                  处方已失效
                </Button>
              </div>
            </div>
          ) : (
            <div className={styles.btnWrapper}>
              <Button
                color="primary"
                fill="solid"
                className={styles.btn}
                onClick={() => toBuy(detail.outPrescriptionId)}
              >
                立即购药
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
