import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface Model {
  addressId: string;
  remark: string;
}

const init: Model = {
  addressId: "",
  remark: ""
};

const orderSlice = createSlice({
  name: "order",
  initialState: init,
  reducers: {
    setAddress: (state: any, action: PayloadAction<string>) => {
      state.addressId = action.payload;
      return state;
    },
    setOrderRemark: (state: any, action: PayloadAction<string>) => {
      state.remark = action.payload;
      return state;
    }
  }
});

export const { setOrderRemark, setAddress } = orderSlice.actions;

export const selectOrder = (rootState: RootState) => rootState.order;

const orderReducer = orderSlice.reducer;
export default orderReducer;
