import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from ".";
import { SystemConfig } from "../models/SystemConfig";

const init: SystemConfig = {
  payTimeLimit: 0,
  yaoOrderPayUrl: "",
  yaoUrlAfterPaid: ""
};

const systemConfigSlice = createSlice({
  name: "systemConfig",
  initialState: init,
  reducers: {
    setSystemConfig: (state: SystemConfig, action: PayloadAction<SystemConfig>) => {
      state = { ...action.payload };
      return state;
    }
  }
});

export const { setSystemConfig } = systemConfigSlice.actions;

export const selectSystemConfig = (rootState: RootState) => rootState.systemConfig;

const systemConfigReducer = systemConfigSlice.reducer;

export default systemConfigReducer;

export const updateSystemSettingsThunk = createAsyncThunk(
  "updateSystemSettingsThunk",
  (config: SystemConfig) => {
    store.dispatch(setSystemConfig(config));
    return Promise.resolve();
  }
);
