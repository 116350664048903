import appStyles from "@/app.module.scss";
import bookIcon from "@/assets/icons/icon_book.png";
import cityIcon from "@/assets/icons/icon_city.png";
import bagIcon from "@/assets/icons/icon_drugbag.png";
import addressIcon from "@/assets/icons/icon_location.png";
import { Button, Divider, Image, Popup, TextArea, Toast } from "antd-mobile";
import { CloseOutline, RightOutline } from "antd-mobile-icons";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Address } from "../../../models/Address";
import { OrderPreVM } from "../../../models/OrderPreVM";
import { getAddressList } from "../../../services/AddressService";
import { createOrder, getOrderPrescriptionDetail } from "../../../services/OrderService";
import { selectOrder, setOrderRemark } from "../../../store/OrderSlice";
import { getFullAddress, getPrice } from "../../../utils/Utils";
import DrugItem from "../components/DrugItem";
import "./antd.scss";
import styles from "./index.module.scss";

export default function Confirmation() {
  const params = useParams();
  const history = useNavigate();
  const orderSelector = useSelector(selectOrder);
  const dispatch = useDispatch();
  const [remark, setRemark] = useState("");
  const [showRemark, setShowRemark] = useState(false);
  const [currRemark, setCurrRemark] = useState(orderSelector.remark);
  const [detail, setDetail] = useState<OrderPreVM>({} as OrderPreVM);
  const [addressList, setAddressList] = useState<Address[]>([]);

  const getData = useCallback(async () => {
    const res = await getOrderPrescriptionDetail(params.id ?? "");
    setDetail(res);
  }, []);

  const getAddresses = useCallback(async () => {
    const res = await getAddressList();
    setAddressList(res);
  }, []);

  const addressId = orderSelector.addressId;

  const defaultAddress = useMemo(() => {
    if (addressList.length > 0) {
      if (addressId) {
        const currAdd = addressList.filter((item) => item.id === addressId);
        if (currAdd.length > 0) {
          return currAdd[0];
        }
      }
      const defaultAdd = addressList.filter((item) => item.isDefault);
      if (defaultAdd.length > 0) {
        return defaultAdd[0];
      }
      return addressList[0];
    }
  }, [addressList, addressId]);

  useEffect(() => {
    document.title = "确认订单";
    getAddresses();
    getData();
  }, []);

  const toAddress = useCallback(() => {
    history(`/address/list?selected=${defaultAddress?.id ?? 0}`);
  }, [defaultAddress?.id]);

  const submit = useCallback(async () => {
    if (!addressId && !defaultAddress?.id) {
      Toast.show({
        icon: "fail",
        content: "请选择收货地址"
      });
      return;
    }
    const data: any = { ...detail };
    data.addresses = null;
    data.addressId = addressId ? addressId : defaultAddress?.id;
    data.remark = currRemark;
    Toast.show({
      duration: 0,
      icon: "loading",
      content: "加载中..."
    });
    const res = await createOrder(data);
    Toast.clear();
    history(`/order/detail/${res.orderId}`, { replace: true });
  }, [detail, currRemark, addressId, defaultAddress]);

  return (
    <div className={classNames(styles.wrapper, appStyles.twoColContainer, "confirm-wrapper")}>
      <div className={appStyles.twoColTop}>
        <div className={styles.block}>
          <div className={styles.presWrapper}>
            <div className={styles.des}>
              <Image src={bookIcon} className={styles.icon}></Image>
              <span className={styles.title}>查看电子处方</span>
            </div>
            <div className={styles.op} onClick={() => history(-1)}>
              <span className={styles.txt}>查看详情</span>
              <RightOutline className={styles.icon} />
            </div>
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.addressWrapper}>
            <div className={styles.titleWrapper}>
              <Image src={addressIcon} className={styles.icon}></Image>
              <span className={styles.title}>地址</span>
            </div>
            <Divider></Divider>
            {defaultAddress ? (
              <div className={styles.addressContent} onClick={toAddress}>
                <div>
                  <div className={styles.userInfo}>
                    {defaultAddress.realName} {defaultAddress.mobile}
                  </div>
                  <div className={styles.addressInfo}>{getFullAddress(defaultAddress)}</div>
                </div>
                <div>
                  <RightOutline className={styles.icon} />
                </div>
              </div>
            ) : (
              <div className={styles.noAddressContent} onClick={toAddress}>
                <div className={styles.tips}>添加收货地址</div>
                <Image src={cityIcon} className={styles.icon}></Image>
              </div>
            )}
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.drugWrapper}>
            <div className={styles.titleWrapper}>
              <Image src={bagIcon} className={styles.icon}></Image>
              <span className={styles.title}>药品信息</span>
            </div>
            <Divider></Divider>
            {detail.items?.map((item) => (
              <DrugItem key={item.id} drug={item}></DrugItem>
            ))}
          </div>
          <Divider></Divider>
          <div className={styles.logisticsInfo}>
            <div className={styles.infoItem}>
              <span className={styles.field}>配送方式</span>
              <span className={styles.val}>普通快递</span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.field}>配送费</span>
              <span className={styles.val}>¥{getPrice(detail.freightPrice)}</span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.field}>备注</span>
              <div
                className={styles.remarkWrapper}
                onClick={() => {
                  setShowRemark(true);
                  // 解决text-area打开高度不变问题
                  setTimeout(() => {
                    setRemark(currRemark);
                  }, 100);
                }}
              >
                {currRemark ? (
                  <span className={styles.val}>{currRemark}</span>
                ) : (
                  <span className={styles.plh}>无备注</span>
                )}
                <RightOutline className={styles.icon} style={{ marginRight: 0 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={appStyles.twoColBottom}>
        <div className={styles.btnWrapper}>
          <div>
            <div className={styles.totalPrice}>¥ {getPrice(detail.totalPrice)}</div>
            <div className={styles.txt}>合计</div>
          </div>
          <Button color="primary" className={styles.btn} onClick={submit}>
            立即支付
          </Button>
        </div>
      </div>

      <Popup
        visible={showRemark}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          height: "60vh",
          overflow: "auto"
        }}
        className="remark-popup"
      >
        <div className={styles.popContent}>
          <div className={styles.header}>
            <span className={styles.title}>备注</span>
            <div
              className={styles.closeWrapper}
              onClick={() => {
                setShowRemark(false);
                setRemark("");
              }}
            >
              <span className={styles.closeTxt}>关闭</span>
              <CloseOutline className={styles.closeIcon} />
            </div>
          </div>
          <Divider></Divider>
          <div className={styles.contentWrapper}>
            <TextArea
              placeholder="请在此处输入内容…"
              value={remark}
              autoSize={{ minRows: 3, maxRows: 10 }}
              showCount
              maxLength={200}
              onChange={(val) => {
                setRemark(val);
              }}
            />
          </div>

          <div className={styles.btnWrapper}>
            <Button
              color="primary"
              className={styles.remarkBtn}
              onClick={() => {
                setCurrRemark(remark);
                setShowRemark(false);
                dispatch(setOrderRemark(remark));
              }}
            >
              确定
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
}
