import { Cascader } from "antd-mobile";
import { CascaderOption } from "antd-mobile/es/components/cascader-view";
import React, { useMemo, useState } from "react";
import { getCities, getCountries, getProvinces } from "../../services/AddressService";
import { useAsyncEffect } from "../../utils/Utils";
import "./antd.scss";

interface OptionModel {
  provinceId: string;
  provinceName: string;
  cityId: string;
  cityName: string;
  countyId: string;
  countyName: string;
}
interface IProps {
  show: boolean;
  value: CascaderOption[];
  onSubmit: (value: CascaderOption[]) => void;
  onClose: () => void;
}
export default function AddressSelector(props: IProps) {
  const { value, onSubmit, onClose, show } = props;
  const [valueToOptions, setValueToOptions] = useState(
    {} as Record<string, CascaderOption[] | null>
  );
  const options = useMemo<CascaderOption[]>(() => {
    function generate(v: string): CascaderOption[] | undefined {
      const options = valueToOptions[v];
      if (options === null) {
        return undefined;
      }
      if (options === undefined) {
        return Cascader.optionSkeleton;
      }
      return options.map((option) => {
        return {
          ...option,
          children: generate(option.label + option.value)
        };
      });
    }
    return generate("") ?? [];
  }, [valueToOptions]);

  async function fetchOptionsForValue(v: CascaderOption, level: number) {
    if (v.label + v.value in valueToOptions) return valueToOptions[v.label + v.value];
    if (level >= 3) {
      setValueToOptions((prev) => {
        return {
          ...prev,
          [v.label + v.value]: null
        };
      });
      return;
    }
    let data: OptionModel[] = [];
    let options: { value: string; label: string }[] | null = null;
    switch (level) {
      case 0:
        data = await getProvinces();
        options = data.map((entry) => {
          return {
            value: entry.provinceId,
            label: entry.provinceName
          };
        });
        break;
      case 1:
        data = await getCities(v.value);
        options = data.map((entry) => {
          return {
            value: entry.cityId,
            label: entry.cityName
          };
        });
        break;
      case 2:
        data = await getCountries(v.value);
        options = data.map((entry) => {
          return {
            value: entry.countyId,
            label: entry.countyName
          };
        });
    }
    setValueToOptions((prev) => {
      return {
        ...prev,
        [v.label + v.value]: options
      };
    });
    return options;
  }

  useAsyncEffect(async () => {
    // const options: Record<string, CascaderOption[] | null> = {};
    await fetchOptionsForValue({ label: "", value: "" }, 0);
    // options[""] = result ? [...result] : null;
    // for (let i = 0; i < value.length - 1; i++) {
    //   const option = value[i];
    //   result = await fetchOptionsForValue({ label: option.label, value: option.value }, i + 1);
    //   options[option.label] = result ? [...result] : null;
    // }
    // setValueToOptions(options);
  }, [value]);

  return (
    <div className="address-selector">
      <Cascader
        className="my-cascader"
        options={options}
        visible={show}
        defaultValue={[]}
        onSelect={(value, extend) => {
          extend.items.forEach((v, index) => {
            fetchOptionsForValue(v ?? { label: "", value: "" }, index + 1);
          });
        }}
        onClose={() => {
          onClose();
        }}
        onConfirm={(value, extend) => {
          onSubmit(extend.items.map((item) => item ?? ({} as CascaderOption)));
        }}
      ></Cascader>
    </div>
  );
}
