const appName = "drugstore_h5_token";

export const setToken = (token: string) => {
  if (token) localStorage.setItem(appName, `Bearer ${token}`);
};

export const getToken = () => localStorage.getItem(appName) || "";

export const clearToken = () => {
  localStorage.setItem(appName, "");
};
