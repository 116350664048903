import {
  Action,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  ThunkAction
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import orderReducer from "./OrderSlice";
import systemConfigReducer from "./SystemSlice";
// 生成总reducer
const rootReducer = combineReducers({
  order: orderReducer,
  systemConfig: systemConfigReducer
});
// 总reducer类型
export type AppRootReducer = ReturnType<typeof rootReducer>;

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
