import { Address } from "../models/Address";
import netService, { ReqMethods } from "./Net";

const apis = {
  url: (id?: string) => `/user/address${id ? "/" + id : ""}`,
  province: "/address/provinces",
  city: (provinceId: string) => `/address/provinces/${provinceId}/cities`,
  country: (cityId: string) => `/address/cities/${cityId}/counties`
};

// 获取所有省份
export const getProvinces = () =>
  netService({
    method: ReqMethods.GET,
    url: apis.province
  });

// 获取某个省份下面的所有市
export const getCities = (provinceId: string) =>
  netService({
    method: ReqMethods.GET,
    url: apis.city(provinceId)
  });

// 获取某个市下面的所有区
export const getCountries = (cityId: string) =>
  netService({
    method: ReqMethods.GET,
    url: apis.country(cityId)
  });

// 获取地址列表
export const getAddressList = () =>
  netService({
    method: ReqMethods.GET,
    url: apis.url()
  });

// 获取地址详情
export const getAddressDetail = (id: string) =>
  netService({
    method: ReqMethods.GET,
    url: apis.url(id)
  });

// 地址-增
export const addAddress = (data: Address) =>
  netService({
    method: ReqMethods.POST,
    url: apis.url(),
    data
  });

// 地址-改
export const updateAddress = (data: Address) =>
  netService({
    method: ReqMethods.PUT,
    url: apis.url(),
    data
  });

// 地址-删
export const deleteAddress = (data: Address) =>
  netService({
    method: ReqMethods.DELETE,
    url: apis.url(),
    data
  });
