import netService, { ReqMethods } from "./Net";

const apis = {
  settings: "/user/system"
};

// 获取系统设置
export const getSystemSettings = () =>
  netService({
    method: ReqMethods.GET,
    url: apis.settings
  });
