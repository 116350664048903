import { Input } from "antd-mobile";
import React, { useRef } from "react";

export function PhoneInput(props: {
  placeholder?: string;
  value?: string;
  onChange?: (val: string) => void;
}) {
  const { placeholder, value, onChange } = props;
  const inputRef = useRef(null);
  return (
    <Input
      ref={inputRef}
      type="number"
      clearable
      placeholder={placeholder}
      value={value}
      onChange={(val: any) => {
        if (val.length > 11) {
          return;
        }
        if (onChange) {
          onChange(val);
        }
      }}
    ></Input>
  );
}
