import netService, { ReqMethods } from "./Net";

const apis = {
  login: "/login/code"
};
// 使用授权码登录
export const loginByCode = (code: string) =>
  netService({
    method: ReqMethods.POST,
    url: apis.login,
    params: { code }
  });
