import appStyles from "@/app.module.scss";
import editIcon from "@/assets/icons/icon_edit.png";
import { Button, Image, Tag } from "antd-mobile";
import { CheckOutline, LocationFill } from "antd-mobile-icons";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Address } from "../../../models/Address";
import { getAddressList } from "../../../services/AddressService";
import { setAddress } from "../../../store/OrderSlice";
import { getFullAddress } from "../../../utils/Utils";
import styles from "./index.module.scss";

export default function AddressList() {
  const [list, setList] = useState<Address[]>([]);
  const history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const selectedId = searchParams.get("selected");

  const getData = useCallback(async () => {
    const res = await getAddressList();
    setList(res);
  }, []);

  const toDetail = useCallback((id?: string) => {
    history(`/address/detail/${id ?? ""}`);
  }, []);

  const selectChange = useCallback((id: string) => {
    if (selectedId) {
      history(-1);
      dispatch(setAddress(id));
    }
  }, []);

  useEffect(() => {
    document.title = "我的地址";
    getData();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={appStyles.twoColContainer}>
        <div className={appStyles.twoColTop}>
          <div className={styles.listWrapper}>
            {list.map((item) => (
              <div key={item.id} className={styles.itemWrapper}>
                <div className={styles.item}>
                  <div className={styles.contentWrapper} onClick={() => selectChange(item.id)}>
                    {selectedId === item.id ? (
                      <div className={styles.selected}>
                        <CheckOutline color="green" fontSize={14} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <div className={styles.titleWrapper}>
                        {item.isDefault ? (
                          <Tag className={styles.tagDefault} color="primary" fill="outline">
                            默认
                          </Tag>
                        ) : (
                          ""
                        )}
                        <span className={styles.name}>{item.realName}</span>
                        <span className={styles.phone}>{item.mobile}</span>
                      </div>
                      <div className={styles.subTitle}>{getFullAddress(item)}</div>
                    </div>
                  </div>
                  <div onClick={() => toDetail(item.id)}>
                    <Image src={editIcon} className={styles.editIcon}></Image>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={appStyles.twoColBottom}>
          <div className={styles.btnWrapper}>
            <Button color="primary" className={styles.btn} onClick={() => toDetail()}>
              <LocationFill color="white" />
              <span style={{ paddingLeft: 6 }}>添加地址</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
