import classnames from "classnames";
import moment from "moment";
import React from "react";
import styles from "./index.module.scss";

interface IProps {
  patientAge: number;
  patientName: string;
  patientGender: string;
  departName: string;
  rpCreateDate: string;
}
export default function PatientInfo(props: IProps) {
  const { patientAge, patientGender, patientName, departName, rpCreateDate } = props;
  return (
    <div className={styles.patientInfo}>
      <div className={classnames(styles.col, styles.left)}>
        <div className={styles.item}>
          <div className={styles.field}>姓名：</div>
          <div className={styles.val}>{patientName}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.field}>年龄：</div>
          <div className={styles.val}>{patientAge}岁</div>
        </div>
        <div className={styles.item}>
          <div className={styles.field}>科室：</div>
          <div className={styles.val}>{departName}</div>
        </div>
      </div>
      <div className={classnames(styles.col, styles.right)}>
        <div className={styles.item}>
          <div className={styles.field}>性别：</div>
          <div className={styles.val}>{patientGender === "1" ? "男" : "女"}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.field}>开方日期：</div>
          <div className={styles.val}>{moment(rpCreateDate).format("YYYY-MM-DD")}</div>
        </div>
      </div>
    </div>
  );
}
