import { Image, Steps } from "antd-mobile";
import { Step } from "antd-mobile/es/components/steps/step";
import classNames from "classnames";
import React from "react";
import "./antd.scss";
import styles from "./index.module.scss";

interface IProp {
  title: string;
  description: string;
  icon: string;
  iconClass?: string;
}

export default function MySteps(props: { steps: IProp[] }) {
  return (
    <div className="my-steps">
      <Steps
        className={styles.myStep}
        direction="vertical"
        style={{
          "--indicator-margin-right": "14px"
        }}
      >
        {props.steps.map((item, index) => (
          <Step
            key={index}
            title={<div className={styles.stepTitle}>{item.title}</div>}
            description={
              <div
                className={styles.stepDes}
                dangerouslySetInnerHTML={{ __html: item.description }}
              ></div>
            }
            icon={<Image src={item.icon} className={classNames(styles.stepIcon, item.iconClass)} />}
            status="wait"
          />
        ))}
      </Steps>
    </div>
  );
}
