import netService, { ReqMethods } from "./Net";

const apis = {
  detail: "/order/pre/detail",
  logistics: (orderId: string) => `/logistics/${orderId}/info`,
  order: "/order",
  orderDetail: (orderId: string) => `/order/${orderId}/detail`,
  yaoToken: `/yao/token`,
  toPayStatus: (orderId: string) => `/order/${orderId}/toPay`,
  checkPayStatus: (orderId: string) => `/order/${orderId}/payStatus`
};

// 获取订单确认详情
export const getOrderPrescriptionDetail = (id: string) =>
  netService({
    method: ReqMethods.GET,
    url: apis.detail,
    params: { outPrescriptionId: id }
  });

// 获取订单列表
export const getOrderList = () =>
  netService({
    method: ReqMethods.GET,
    url: apis.order
  });

// 查询订单物流信息
export const getOrderLogistics = (orderId: string) =>
  netService({
    method: ReqMethods.GET,
    url: apis.logistics(orderId)
  });

// 创建订单
export const createOrder = (data: any) =>
  netService({
    method: ReqMethods.POST,
    url: apis.order,
    data
  });

// 获取订单详情
export const getOrderDetail = (id: string) =>
  netService({
    method: ReqMethods.GET,
    url: apis.orderDetail(id)
  });

// 去支付，更改支付状态
export const toPayStatus = (id: string) =>
  netService({
    method: ReqMethods.PUT,
    url: apis.toPayStatus(id)
  });
// 查询支付状态
export const checkPayStatus = (id: string) =>
  id
    ? netService({
      url: apis.checkPayStatus(id)
    })
    : null;

// 获取1药网token
export const getYaoToken = () =>
  netService({
    url: apis.yaoToken
  });
