// useEffect中异步获取数据（注意：eslint-disable-line注释不能删除，该注释用于关闭eslint的deps auto fix）

import { DependencyList, useEffect } from "react";
import { Address } from "../models/Address";

// 该方法无法处理组件销毁时的清理工作
export const useAsyncEffect = (fun: () => Promise<any>, deps: DependencyList) => {
  useEffect(() => {
    fun();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export const getFullAddress = (address: Address) =>
  address.provinceName + address.cityName + address.countyName + address.address;

export const getPrice = (origin: number) => ((origin ? origin : 0) / 100).toFixed(2);
/**
 * 获取地址栏里某个参数的值
 * @param paramName 参数名称
 * @returns 参数值
 */
export const getQueryVariableValue = (paramName: string) => {
  const query = location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === paramName) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
};
