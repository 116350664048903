import bookIcon from "@/assets/icons/icon_book.png";
import iconCar from "@/assets/icons/icon_car1.png";
import bagIcon from "@/assets/icons/icon_drugbag.png";
import fileIcon from "@/assets/icons/icon_file1.png";
import addressIcon from "@/assets/icons/icon_location.png";
import payFailIcon from "@/assets/icons/icon_pay_fail.png";
import paySuccessIcon from "@/assets/icons/icon_pay_success.png";
import payWaitIcon from "@/assets/icons/icon_pay_wait.png";
import { Button, Divider, Image, Popup, SpinLoading, Toast } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import classNames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Order } from "../../../models/Order";
import {
  checkPayStatus,
  getOrderDetail,
  getYaoToken,
  toPayStatus
} from "../../../services/OrderService";
import { selectSystemConfig } from "../../../store/SystemSlice";
import { OrderStatus } from "../../../utils/Const";
import { getPrice, getQueryVariableValue } from "../../../utils/Utils";
import DrugItem from "../components/DrugItem";
import LogisticsInfo from "../components/LogisticsInfo";
import PayTimer from "../components/PayTimer";
import "./antd.scss";
import styles from "./index.module.scss";

export default function OrderDetail() {
  const [showLogisticsInfo, setShowLogisticsInfo] = useState(false);
  const [detail, setDetail] = useState<Order>({} as Order);
  const [showLoading, setShowloading] = useState(false);
  const [needPay, setNeedPay] = useState(false);
  const initalState = 0;
  const counterRef = useRef(initalState);
  const params = useParams();
  const history = useNavigate();
  const systemConfig = useSelector(selectSystemConfig);

  // 倒计时结束时调用查询订单的接口 刷新订单状态
  const countDownCallback = useCallback(() => {
    getDetail();
  }, []);
  const statusContent = useMemo(() => {
    switch (detail.payStatus) {
      case "PAY_SUCCESS":
        return (
          <div className={styles.successTop}>
            <div className={styles.statusContent}>
              <Image src={paySuccessIcon} className={styles.icon}></Image>
              <span className={styles.des}>支付成功</span>
            </div>
            <NavLink to={"/order/list"} className={styles.backList}>
              查看列表
            </NavLink>
          </div>
        );
      case "PAY_FAIL":
        // 支付失败，需要检查还剩余多少支付时间=systemConfig.payTimeLimit-(现在的时间-下单的时间)
        return (
          <>
            <div className={styles.statusContent}>
              <Image src={payFailIcon} className={styles.icon}></Image>
              <span className={classNames(styles.des, styles.red)}>支付失败</span>
            </div>
            <div className={styles.extraInfo}>
              支付失败，请在
              <span className={styles.clock}>
                <PayTimer
                  createTime={detail.createdDate}
                  limitTime={systemConfig.payTimeLimit}
                  callback={countDownCallback}
                ></PayTimer>
              </span>
              内重新支付
            </div>
          </>
        );
      case "NO_PAY":
        switch (detail.orderStatus) {
          case "AUTO_CANCEL":
            return (
              <>
                <div className={styles.statusContent}>
                  <Image src={payFailIcon} className={styles.icon}></Image>
                  <span className={classNames(styles.des, styles.red)}>支付失败</span>
                </div>
                <div className={styles.extraInfo}>支付超时订单已取消，请重新购买</div>
              </>
            );
        }
        // 剩余时间：=systemConfig.payTimeLimit-(现在的时间-下单的时间)
        return (
          <>
            <div className={styles.statusContent}>
              <Image src={payWaitIcon} className={styles.icon}></Image>
              <span className={classNames(styles.des, styles.red)}>等待支付</span>
            </div>
            <div className={styles.extraInfo}>
              剩余时间：
              <span className={styles.clock}>
                <PayTimer
                  createTime={detail.createdDate}
                  limitTime={systemConfig.payTimeLimit}
                  callback={countDownCallback}
                ></PayTimer>
              </span>
            </div>
          </>
        );
      default:
        return;
    }
  }, [detail, systemConfig]);

  const btnText = useMemo(() => {
    switch (detail.payStatus) {
      case "PAY_FAIL":
        return "立即支付";
      case "NO_PAY":
        if (detail.orderStatus === "AUTO_CANCEL") {
          return "再次购买";
        }
        return "立即支付";
    }
  }, [detail.payStatus, detail.orderStatus]);

  const onPay = useCallback(async () => {
    // 需要兼容处理一下，1药网的订单跳转到1药网支付，其他订单去其他渠道处理
    // 处理重新购买的逻辑，要给用户重新下单
    setShowloading(true);
    const yaoToken = await getYaoToken();
    setShowloading(false);
    const backUrl = `${systemConfig.yaoUrlAfterPaid}/order/detail/${detail.id}?from=yaowang`;
    const url =
      `${systemConfig.yaoOrderPayUrl}?orderId=${detail.yaoOrderId}&token=${yaoToken.token}` +
      `&backLink=${encodeURIComponent(backUrl)}`;
    console.log("urlurl=", url);
    Toast.show({
      duration: 0,
      icon: "loading",
      content: "加载中..."
    });
    await toPayStatus(params.id ?? "");
    Toast.clear();
    window.location.href = url;
  }, [detail]);

  const getDetail = useCallback(async () => {
    Toast.show({
      duration: 0,
      icon: "loading",
      content: "加载中..."
    });
    const res = await getOrderDetail(params.id ?? "");
    setDetail(res);
    if (
      res.orderStatus === "CREATE" ||
      res.orderStatus === "WAIT_PAY" ||
      res.orderStatus === "PAYING"
    ) {
      setNeedPay(true);
    } else {
      setNeedPay(false);
    }
    Toast.clear();
  }, []);

  const latestLogisticsInfo = useMemo(() => {
    const logistics = detail.logisticsItems?.[0];
    if (!logistics?.content) {
      return;
    }
    const content = JSON.parse(logistics?.content);
    return content?.[0];
  }, [detail.logisticsItems]);

  useEffect(() => {
    document.title = "订单详情";
    getDetail();
  }, []);
  useEffect(() => {
    const source = getQueryVariableValue("from");
    // 如果是1药网，轮询接口5秒钟，如果没有结果，判定失败。
    if (source && source === "yaowang") {
      // 开启定时器轮询订单，10s后 确认失败，倒计时结束后再刷一次订单状态，应该为已关闭
      // 计时器
      const timer = setInterval(
        async (count) => {
          console.log("count=", counterRef.current);
          if (counterRef.current < 10) {
            const res = await checkPayStatus(params.id ?? "");
            if (res.payStatus && res.payStatus === "PAY_SUCCESS") {
              clearInterval(timer);
              getDetail();
            } else {
              counterRef.current = count + 1;
            }
          } else if (counterRef.current >= 10) {
            clearInterval(timer);
          }
        },
        1000,
        counterRef.current
      );
      // 组件卸载时，清除计时器
      return () => {
        if (timer) {
          clearInterval(timer);
        }
      };
    }
  }, []);
  return (
    <div className={classNames(styles.wrapper, "detail-wrapper")}>
      <div className={styles.statusWrapper}>{statusContent}</div>
      {detail.orderStatus === "DELIVER" ? (
        <div className={styles.block}>
          <div className={styles.headerWrapper}>
            <div className={styles.titleWrapper}>
              <Image src={iconCar} className={styles.icon}></Image>
              <span className={styles.title}>物流信息</span>
            </div>
            <div className={styles.op} onClick={() => setShowLogisticsInfo(true)}>
              <span className={styles.txt}>查看物流详情</span>
              <RightOutline className={styles.icon} />
            </div>
          </div>
          <Divider></Divider>
          <div className={classNames(styles.contentWrapper, styles.logisticsContent)}>
            <div className={styles.latestLogisticsInfo}>{latestLogisticsInfo?.context}</div>
            <div className={styles.time}>
              {moment(latestLogisticsInfo?.time).format("MM-DD HH:mm")}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={styles.block}>
        <div className={styles.headerWrapper}>
          <div className={styles.titleWrapper}>
            <Image src={addressIcon} className={styles.icon}></Image>
            <span className={styles.title}>地址</span>
          </div>
        </div>
        <Divider></Divider>
        <div className={classNames(styles.contentWrapper, styles.addressContent)}>
          <div className={styles.userInfo}>
            {detail.orderConsigneeData?.realName} {detail.orderConsigneeData?.mobile}
          </div>
          <div className={styles.addressInfo}>
            {detail.orderConsigneeData?.provinceName
              ? detail.orderConsigneeData?.provinceName +
              detail.orderConsigneeData?.cityName +
              detail.orderConsigneeData?.countyName +
              detail.orderConsigneeData?.address
              : ""}
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.headerWrapper}>
          <div className={styles.titleWrapper}>
            <Image src={bookIcon} className={styles.icon}></Image>
            <span className={styles.title}>查看电子处方</span>
          </div>
          <div
            className={styles.op}
            onClick={() => history(`/prescription?prescriptionId=${detail.outPrescriptionId}`)}
          >
            <span className={styles.txt}>查看详情</span>
            <RightOutline className={styles.icon} />
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.headerWrapper}>
          <div className={styles.titleWrapper}>
            <Image src={bagIcon} className={styles.icon}></Image>
            <span className={styles.title}>药品信息</span>
          </div>
        </div>
        <Divider></Divider>
        <div className={classNames(styles.contentWrapper, styles.drugWrapper)}>
          {detail.items?.map((drug) => (
            <DrugItem key={drug.id} drug={drug}></DrugItem>
          ))}
          <div className={styles.distributionInfo}>
            <span className={styles.field}>配送费</span>
            <div>
              {/* <span className={styles.price}>¥5</span> */}
              <span className={styles.realPrice}>¥{getPrice(detail.freightPrice)}</span>
            </div>
          </div>
          <Divider></Divider>
          <div className={styles.priceWrapper}>
            <span className={styles.txt}>合计</span>
            <span className={styles.totalPrice}>¥ {getPrice(detail.payPrice)}</span>
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.headerWrapper}>
          <div className={styles.titleWrapper}>
            <Image src={fileIcon} className={styles.icon}></Image>
            <span className={styles.title}>订单信息</span>
          </div>
        </div>
        <Divider></Divider>
        <div className={classNames(styles.contentWrapper, styles.orderInfoWrapper)}>
          <div className={styles.item}>
            <span className={styles.field}>订单号码</span>
            <span className={styles.val}>{detail.id}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.field}>订单状态</span>
            <span className={styles.val}>
              {OrderStatus[detail.orderStatus as keyof typeof OrderStatus]}
            </span>
          </div>
          <div className={styles.item}>
            <span className={styles.field}>下单时间</span>
            <span className={styles.val}>
              {moment(detail.createdDate).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
          {detail.payStatus === "PAY_SUCCESS" ? (
            <div className={styles.item}>
              <span className={styles.field}>支付方式</span>
              <span className={styles.val}>微信支付</span>
            </div>
          ) : (
            ""
          )}
          <div className={styles.item}>
            <span className={styles.field}>备注</span>
            <div className={styles.val}>
              <span style={{ textAlign: "left", display: "inline-block" }}>{detail.remark}</span>
            </div>
          </div>
        </div>
      </div>
      {!needPay ? (
        <div className={styles.declare}>—— {detail.hospitalName}为您提供服务 ——</div>
      ) : (
        <div className={styles.btnWrapper}>
          <Button color="primary" className={styles.btn} onClick={onPay}>
            {btnText}
          </Button>
        </div>
      )}
      <Popup
        visible={showLogisticsInfo}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          maxHeight: "70vh",
          overflow: "auto"
        }}
      >
        <LogisticsInfo
          logistics={detail.logisticsItems?.[0]}
          close={() => setShowLogisticsInfo(false)}
        ></LogisticsInfo>
      </Popup>
      {showLoading ? (
        <div className={styles.loadingWrapper}>
          <SpinLoading style={{ "--size": "48px" }} />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
