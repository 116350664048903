import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

interface ITimerProps {
  createTime: string; // 计时开始时间，订单创建的时间
  limitTime: number; // 超时时间，单位为分钟
  callback?: () => void; // 超时时回调
}
// 从订单创建时间开始倒计时，倒计时结束时调用查询订单的接口 刷新订单状态
function PayTimer(props: ITimerProps) {
  const { createTime, limitTime, callback } = props;
  // 超时时间的时间戳
  const endTime = useMemo(() => Number(moment(createTime).valueOf()) + limitTime * 60 * 1000, [createTime, limitTime]);

  // 小时：分钟：秒钟
  const getOrderTimeLeft = useCallback(() => {
    // 剩余的秒 取余数//倒计时中的时针
    let leftHours = String(moment(endTime).diff(moment(), "hour") % 24);
    if (leftHours.length == 1) {
      // padding
      leftHours = "0" + leftHours;
    }
    // 倒计时中的分针
    let leftMinutes = String(moment(endTime).diff(moment(), "minute") % 60);
    if (leftMinutes.length == 1) {
      // padding
      leftMinutes = "0" + leftMinutes;
    }
    // 倒计时中的秒针
    // console.log("diff = ", moment(endTime).format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss"), moment(endTime).diff(moment(), "second"));
    let leftSeconds = String(moment(endTime).diff(moment(), "second") % 60);
    if (leftSeconds.length == 1) {
      // padding
      leftSeconds = "0" + leftSeconds;
    }
    return leftHours + ":" + leftMinutes + ":" + leftSeconds;
  }, [endTime]);

  // 与当前时间的间隔
  const [timeStrLeft, setTimeStrLeft] = useState("");

  // 计算剩余时间
  const calc = useCallback(
    (timer: NodeJS.Timeout) => {
      // 0秒
      const seconds = moment(endTime).diff(moment(), "second");
      // 清除计时器
      if (seconds <= 0) {
        setTimeStrLeft("已超时");
        if (seconds <= -3) {
          console.log("超时", seconds);
          clearInterval(timer);
          if (callback) {
            callback();
          }
        }
      } else {
        setTimeStrLeft(getOrderTimeLeft());
      }
    },
    [endTime, callback]
  );

  useEffect(() => {
    // 计时器
    const timer = setInterval(() => {
      calc(timer);
    }, 1000);

    // 组件卸载时，清除计时器
    return () => {
      clearInterval(timer);
    };
  }, [calc]);

  return <span>{timeStrLeft}</span>;
}

export default PayTimer;
