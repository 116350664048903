import { Toast } from "antd-mobile";
import axios, { AxiosRequestConfig, ResponseType } from "axios";
import { clearToken, getToken } from "../utils/Token";

export interface ErrorMessage {
  message?: string;
  statusCode?: string;
}

export const ReqMethods = {
  GET: "get",
  POST: "post",
  PATCH: "patch",
  PUT: "put",
  DELETE: "delete"
};

const globalVal = window as any;
export const baseURL = (globalVal.BASE_API_URL || "") + "/api/h5";
const axiosOption = {
  timeout: 60000,
  baseURL
};
const service = axios.create(axiosOption);

service.interceptors.request.use(
  (config) => {
    const data = config;
    if (getToken()) {
      data.headers = { ...config.headers, ...{ Authorization: getToken() } };
    }
    return data;
  },
  (error) => Promise.reject(error)
);

service.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const defaultConfig = {
  responseType: "json" as ResponseType,
  withCredentials: false,
  headers: {
    Authorization: "charset=UTF-8"
  }
};

const ajax = (config: AxiosRequestConfig) =>
  service({ ...defaultConfig, ...config })
    .then((resource) => resource.data)
    .catch((error: any) => {
      const msg = {
        statusCode: "",
        message: ""
      };
      if (error.response && error.response.data) {
        if (error.response.data.title) {
          msg.message = error.response.data.title;
        } else if (Array.isArray(error.response.data.fieldErrors)) {
          msg.message = error.response.data.fieldErrors.map((item: any) => item.message).join("、");
        }
        msg.statusCode = error.response.status;

        if (Number(msg.statusCode) === 401) {
          clearToken();
          msg.message = "登录信息失效，请重新登录";
        } else {
          Toast.show({
            icon: "fail",
            content: msg.message ? msg.message : "出错：" + msg.statusCode
          });
        }
      }
      return Promise.reject(msg);
    });

export default ajax;
